<div class="modal-header">
  <h5 class="modal-title" id="staticBackdropLabel">Reset Password</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
    <img src="../../../assets/img/modal-close.svg" alt="">
  </button>
</div>
<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
  <div class="modal-body modal-form">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group input-wrp">
          <label for="oldPassword" class="form-label">Old password <span class="text-danger"> * </span></label>
          <div class="password-field-wrp">
            <input [type]="showOldPassword ? 'text' : 'password'" id="oldPassword" class="form-control"
              formControlName="oldPassword" (paste)="disableCopyPaste($event)">
            <button type="button" class="show-hide" (click)="togglePasswordVisibility('oldPassword')">
              <img *ngIf="!showOldPassword" src="../../../assets/img/icon-hide-password.svg" alt="">
              <img *ngIf="showOldPassword" src="../../../assets/img/icon-show-password.svg" alt="">
            </button>
          </div>
          <!-- <app-form-error  [control]="resetPasswordForm.controls['oldPassword']" validatorName="required">
            Email is required
          </app-form-error> -->
          <div *ngIf="resetPasswordForm.get('oldPassword')?.touched && resetPasswordForm.get('oldPassword')?.invalid">
            <small class="text-danger err-msg form-error"
              *ngIf="resetPasswordForm.get('oldPassword')?.errors?.['required']">Old Password
              is required.</small>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="form-group input-wrp">
          <label for="newPassword" class="form-label">New password <span class="text-danger"> * </span></label>
          <div class="password-field-wrp">
            <input [type]="showNewPassword ? 'text' : 'password'" id="newPassword" class="form-control"
              formControlName="newPassword" (paste)="disableCopyPaste($event)">
            <button type="button" class="show-hide" (click)="togglePasswordVisibility('newPassword')">
              <img *ngIf="!showNewPassword" src="../../../assets/img/icon-hide-password.svg" alt="">
              <img *ngIf="showNewPassword" src="../../../assets/img/icon-show-password.svg" alt="">
            </button>
          </div>

          <div *ngIf="resetPasswordForm.get('newPassword')?.touched && resetPasswordForm.get('newPassword')?.invalid">
            <small class="text-danger err-msg form-error"
              *ngIf="resetPasswordForm.get('newPassword')?.errors?.['required']">
              New Password is required.
            </small>
            <small class="text-danger err-msg form-error"
            *ngIf="resetPasswordForm.get('newPassword')?.errors?.['maxlength']">
            Password cannot exceed 50 characters.
          </small>
            <small class="text-danger err-msg form-error" *ngIf="
              resetPasswordForm.get('newPassword')?.errors?.['passwordInvalid'] &&
              !resetPasswordForm.get('newPassword')?.errors?.['required']
            ">
              Password must be minimum 8 characters with uppercase, lowercase, number, and special
              character.
            </small>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="form-group input-wrp">
          <label for="confirmPassword" class="form-label">Confirm password <span class="text-danger"> * </span></label>
          <div class="password-field-wrp">
            <input [type]="showConfirmPassword ? 'text' : 'password'" id="confirmPassword" class="form-control"
              formControlName="confirmPassword">
            <button type="button" class="show-hide" (click)="togglePasswordVisibility('confirmPassword')">
              <img *ngIf="!showConfirmPassword" src="../../../assets/img/icon-hide-password.svg" alt="">
              <img *ngIf="showConfirmPassword" src="../../../assets/img/icon-show-password.svg" alt="">
            </button>
          </div>
          <!-- <app-form-error  [control]="resetPasswordForm.controls['confirmPassword']" validatorName="required">
            Password is required.
          </app-form-error> -->
          <!-- <app-form-error  [control]="resetPasswordForm.controls['confirmPassword']" validatorName="mustMatch">
            Passwords must match.
          </app-form-error> -->
          <div
            *ngIf="resetPasswordForm.get('confirmPassword')?.touched && resetPasswordForm.get('confirmPassword')?.invalid">
            <small class="text-danger err-msg form-error"
              *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['required']">Confirm
              Password is required.</small>
            <small class="text-danger err-msg form-error"
              *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['mustMatch']">Passwords
              must match.</small>
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="close()">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="loading">
      <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
      <span *ngIf="!loading">Submit</span>
    </button>
  </div>

</form>
import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CommonValidator {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    const regex = /^[^\s]+(\s+[^\s]+)*$/;
    if (!control.value.toString().match(regex)) {
      return { cannotContainSpace: true };
    }

    return null;
  }



  static websiteLink(control: AbstractControl): ValidationErrors | null {
    const regexWithProtocol = /^(ftp|http|https):\/\/[^\s"]+$/;

    const regexWithoutProtocol = /^(?!.*(http|https|ftp)[^\s:]+)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?::\d+)?(\/[^\s]*)?$/;
  
    const isValid =
      (control.value && regexWithProtocol.test(control.value)) || 
      (control.value && regexWithoutProtocol.test(control.value)); 
  

  if (control.value && !isValid) {
    return { websiteLink: true };
  }
    return null;
  }

  static websiteTitle(control: AbstractControl): ValidationErrors | null {
    const regex = /^(?!\s)(?!.*\s{2,})([A-Za-z0-9 .,'-]+)(?<!\s)$/;
    if (control.value && !control.value.match(regex)) {
      return { websiteTitle: true };
    }

    return null;
  }

  static clientName(control: AbstractControl): ValidationErrors | null {
    const regex = /^(?!\s)([A-Za-z]+)(\s[A-Za-z]+)?(\s[A-Za-z]+)?(?!\s)$/;
    if (control.value && !control.value.match(regex)) {
      return { clientName: true };
    }

    return null;
  }

  static firstName(control: AbstractControl): ValidationErrors | null {
    const regex = /^[A-Za-z]{2,75}$/;
    if (control.value && !control.value.match(regex)) {
      return { firstName: true };
    }

    return null;
  }

  static fullName(control: AbstractControl): ValidationErrors | null {
    const regex = /^[A-Za-z\s]{2,100}$/; // Allows alphabets and spaces with length between 2 and 100
    if (control.value && !control.value.match(regex)) {
      return { name: true }; // Return an error key if the validation fails
    }
  
    return null; // Return null if valid
  }
  static lastName(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const words = control.value.split(" ");
      const regex = /^[A-Za-z]{1,75}$/;
      if (words.length > 2 || words.some((value: string) => !value.match(regex))) {
        return { lastName: true };
      }
    }

    return null;
}


  static email(control: AbstractControl): ValidationErrors | null {
    const regex = /^(?![-\._\+])(?!.*[\._\+-]{2,})[\w-\.\+]+@(?:[a-z]{2,}\.)+[a-z]{2,}$/;
    if (control.value && !control.value.match(regex)) {
      return { commonEmail: true };
    }

    return null;
  }

  static activationCode(control: AbstractControl): ValidationErrors | null{
    const regex = /^[a-zA-Z0-9]+$/;
    if (!control.value.match(regex)) {
      return { onlyLettersAndNumbers: true };
    }

    return null;
  }

  static activationCodeLength(control: AbstractControl): ValidationErrors | null{
    const regex = /^(.){4,15}$/;
    if (!control.value.match(regex)) {
      return { length: true };
    }

    return null;
  }
}
export function MustMatch(controlName: string, matchingControlName: string) {

  return (control: AbstractControl): ValidationErrors | null => {
    const input = control.get(controlName);
    const matchingInput = control.get(matchingControlName);

    if (input === null || matchingInput === null) {
      return null;
    }

    if (
      matchingInput?.errors &&
      !matchingInput.errors['confirmPasswordValidator']
    ) {
      return null;
    }

    if (input.value !== matchingInput.value) {
      matchingInput.setErrors({ confirmPasswordValidator: true });
      return { confirmPasswordValidator: true };
    } else {
      matchingInput.setErrors(null);
      return null;
    }
  };
}
import {  Component, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../modules/service/auth-service';
import { StepService } from '../step.service';

@Component({
  selector: 'app-shared-header',
  templateUrl: './shared-header.component.html',
  styleUrl: './shared-header.component.scss'
})
export class SharedHeaderComponent {
  @Input() showButton1: boolean = true; // Default: show Button 1
  @Input() showButton2: boolean = true;
  private _activeSection: string = '';
  isScrolled = false;
  planPeriod: string = 'annually'
  imageSrc: string = '../../../../assets/img/register-icon.svg';

  constructor(private renderer: Renderer2, private router: Router, private el: ElementRef,
    private authService: AuthService, private toasterService: ToastrService,
    private stepService: StepService,) { }

  @HostListener('window:resize', [])
  onResize() {
    if (window.innerWidth >= 991) {
      // You can trigger any action based on window resize
    }
  }

  scrollToSection(event: Event, targetId: string): void {
    event.preventDefault();
    this.router.navigate([''], { fragment: targetId.substring(1) });
    
  }
  navigate() {
    this.router.navigate(['/signin'])
  }

  navigateToRegister() {
    this.router.navigate(['/signup'])
  }




  get activeSection(): string {
    return this._activeSection;
  }

  set activeSection(section: string) {
    this._activeSection = section;
    this.collapseNavbar();
  }


  collapseNavbar(): void {
    const navbarNav = this.el.nativeElement.querySelector('#navbarNav');
    const navbarToggler = this.el.nativeElement.querySelector('.navbar-toggler');

    if (navbarNav && navbarNav.classList.contains('show')) {
      // Remove the 'show' class to collapse the navbar
      this.renderer.removeClass(navbarNav, 'show');
      // Add the 'collapsed' class to the toggler button to indicate it's collapsed
      this.renderer.addClass(navbarToggler, 'collapsed');
    }
  }

 
  onMouseEnter() {
    this.imageSrc = '../../../../assets/img/register-hover-icon.svg';  
  }

}

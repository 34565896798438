import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../modules/service/profile-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  loading: boolean = false

  constructor(private fb: FormBuilder, private activeModal: NgbActiveModal, 
    private profileService: ProfileService, private toaster: ToastrService) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, this.passwordValidator(),Validators.maxLength(50)]],
      confirmPassword: ['', Validators.required]
    }, {
      validators: this.mustMatch('newPassword', 'confirmPassword')
    });
  }

  togglePasswordVisibility(fieldId: string): void {
    const field = document.getElementById(fieldId) as HTMLInputElement;
    if (field) {
      const fieldType = field.type === 'password' ? 'text' : 'password';
      field.type = fieldType;

      if (fieldId === 'oldPassword') {
        this.showOldPassword = fieldType === 'text';
      } else if (fieldId === 'newPassword') {
        this.showNewPassword = fieldType === 'text';
      } else if (fieldId === 'confirmPassword') {
        this.showConfirmPassword = fieldType === 'text';
      }
    }
  }

  mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (matchingControl?.errors && !matchingControl.errors['mustMatch']) {
        return null;
      }

      if (control?.value !== matchingControl?.value) {
        matchingControl?.setErrors({ mustMatch: true });
      } else {
        matchingControl?.setErrors(null);
      }

      return null;
    };
  }

  close(): void {
    this.activeModal.close();
  }

  onSubmit(): void {
    if (this.resetPasswordForm.invalid) {
      Object.keys(this.resetPasswordForm.controls).forEach(control => {
        this.resetPasswordForm.get(control)?.markAsTouched();
      });
      return;
    }

    if (this.resetPasswordForm.valid) {

      this.loading = true
      let payload = this.resetPasswordForm.value;

      this.profileService
      .resetPassword(payload)
      .subscribe((value:any)=>{
        
        if (value.success && value.statusCode === 200) {
            this.loading = false
            this.toaster.success(value.message, "Success", {
              progressBar: true,
              // toastClass: "toast ngx-toastr",
              closeButton: true,
              timeOut: 3000,
              });
            this.activeModal.close();
          }
          this.loading = false;
      },(err:any)=>{
        console.log(err)
        this.toaster.error(err, "Error!", {
          progressBar: true,
          // toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 3000,
          });
        this.loading = false;
      });
      // .subscribe({
      //   next: (value: any) => {
      //     if (value.success && value.statusCode === 200) {
      //       this.loading = false
      //       this.activeModal.close();

      //     }
      //     this.loading = false;
      //   },
      //   error: (err: any) => {
      //     this.toaster.error(err);
      //     this.loading = false;
      //   },
      // });
    }
  }


   passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*?&])(?=.{8,})/;

      if (!passwordPattern.test(value)) {
        return { passwordInvalid: true };
      }

      return null;
    };
  }

  disableCopyPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.toaster.warning("Copy-pasting is disabled.","Warning!",{
      progressBar: true,
      closeButton: true,
      timeOut: 3000,
    })
  }
}

<section class="header-banner-bg scrollsec" id="home">
    <header class="header" id="home" [ngClass]="{'scrolled': isScrolled}">
      <nav class="navbar navbar-expand-lg">
        <div class="container custom-container">

          <a class="navbar-brand logo-head" href="#">
            <img src="../../../../assets/img/logo.svg" alt="Logo">
          </a>

          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-ico"></span>
            <span class="navbar-toggler-ico"></span>
            <span class="navbar-toggler-ico"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item nav-cursor">
                <a class="nav-link" [ngClass]="{'active': activeSection === '#home'}"
                  (click)="scrollToSection($event, '#home')">
                  Home
                </a>
              </li>
              <li class="nav-item nav-cursor">
                <a class="nav-link" [ngClass]="{'active': activeSection === '#about'}"
                  (click)="scrollToSection($event, '#about')">
                  About Us
                </a>
              </li>
              <li class="nav-item nav-cursor">
                <a class="nav-link" [ngClass]="{'active': activeSection === '#about-us'}"
                  (click)="scrollToSection($event, '#about-us')">
                  Features
                </a>
              </li>
              <li class="nav-item nav-cursor">
                <a class="nav-link" [ngClass]="{'active': activeSection === '#pricing'}"
                  (click)="scrollToSection($event, '#pricing')">
                  Pricing
                </a>
              </li>
              <li class="nav-item nav-cursor">
                <a class="nav-link" [ngClass]="{'active': activeSection === '#contact-us'}"
                  (click)="scrollToSection($event, '#contact-us')">
                  Contact Us
                </a>
              </li>
              <li class="nav-item nav-cursor">
                <a class="nav-link" [ngClass]="{'active': activeSection === '#faq'}"
                  (click)="scrollToSection($event, '#faq')">
                  FAQ
                </a>
              </li>
            </ul>

            <!-- Login button -->
            <button *ngIf="showButton1" class="btn btn-primary login-btn" type="button" (click)="navigate()">
              <span>Login</span><span><img src="../../../../assets/img/export.svg" alt="export"></span>
            </button>
            <!-- <button class="btn btn-primary login-btn" type="button" (click)="navigateToRegister()">
              <span>Register</span><span><img src="../../../../assets/img/register-icon.svg" alt="export"></span>
            </button> -->
            <button *ngIf="showButton2" class="btn btn-primary login-btn" type="button" (click)="navigateToRegister()" (mouseenter)="onMouseEnter()">
              <span>Register</span>
              <span>
                <img [src]="imageSrc" alt="register-icon">
              </span>
            </button>

          </div>
        </div>
      </nav>
    </header>
    </section>